import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarModule,
	MatSnackBarRef
} from '@angular/material/snack-bar';

import { SnackbarType } from '@@shared/snackbar';

@Component({
	selector: 'sl-snackbar',
	templateUrl: './snackbar.component.html',
	styleUrl: './snackbar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		MatSnackBarModule,
		MatProgressSpinner
	]
})
export class SnackbarComponent {
	readonly SnackbarType = SnackbarType;

	// Services declaration
	readonly data = inject(MAT_SNACK_BAR_DATA) as {
		message: string;
		action?: string;
		showDismiss?: boolean;
		type?: SnackbarType;
	};
	readonly #snackBarRef = inject(MatSnackBarRef<SnackbarComponent>);

	dismiss(): void {
		this.#snackBarRef.dismiss();
	}

	action(): void {
		this.#snackBarRef.dismissWithAction();
	}
}
