<div
	class="sl-snackbar-wrapper sl-width-100 sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-space-between sl-flexbox-gap-8">
	<div class="sl-snackbar-content">
		@if (data.type === SnackbarType.loader) {
			<div class="sl-snackbar-loader">
				<mat-spinner color="red" diameter="18"></mat-spinner>
			</div>
		}
		{{ data.message }}
	</div>
	@if (!!data.action && data.type !== SnackbarType.loader) {
		<button	mat-button
			type="button"
			class="sl-snackbar-action"
			(click)="action()">
			{{ data.action }}
		</button>
	}
	@if (data.showDismiss) {
		<mat-icon class="material-symbols-outlined clickable"
			(click)="dismiss()">
			close
		</mat-icon>
	}
</div>
